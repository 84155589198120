<template>
  <div class="contents">
    <p>Tips: 点击图片点燃烟花</p>
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="index in [1,2,3,4,5]" :key="index">
        <img :src="require(`@/assets/fireworks/${index}.jpg`)" @click="setOffFirework(index)" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "FireworkLayout",
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 50,
        loop: true
      }
    };
  },
  methods: {
    setOffFirework(index) {
      this.$socket.emit("client_set_off_firework", {kind: index})
    }
  },
  mounted(){
    // this.$socket.on("connect", (arg) => {
    //   console.log("connect", arg);
    // })
    // this.$socket.on("disconnect", (arg) => {
    //   console.log("disconnect", arg);
    // })
    // this.sockets.subscribe("set_off_firework", (arg) => {
    //   console.log(arg);
    // })
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
};
</script>

<style scoped>
.contents {
  background: url("../assets/bg-imgage.jpg");
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  font-family:"Microsoft YaHei",微软雅黑,"Microsoft JhengHei",华文细黑,STHeiti,MingLiu;
  padding-top: 200px;
}

.swiper-wrapper img{
  width: 100%;
  height: auto;
}
.contents p {
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}
</style>

<style>
  html, body, #app{
    height: 100%;
  }
</style>