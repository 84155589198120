import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ECharts from 'vue-echarts'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/style/style.css'
import { mockXHR } from './mock/'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

import DefaultLayout from './layouts/default.vue'
import ControlLayout from './layouts/control.vue'
import FireworkLayout from './layouts/firework.vue'
Vue.component('DefaultLayout',DefaultLayout);
Vue.component('ControlLayout',ControlLayout);
Vue.component('FireworkLayout',FireworkLayout);

import 'video.js/dist/video-js.css'
// Vue.prototype.randArray = function randArray(len, min, max) {
// 	return Array.from({length:len}, v=> Math.floor(Math.random()*(max-min))+min);
// }

// if(process.env.NODE_ENV == 'development'){
//   mockXHR();
// }
mockXHR();

Vue.config.productionTip = false
Vue.component('v-chart', ECharts)
Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'));

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO(process.env.VUE_APP_SOCKET_SERVER_URL, {
    path: '/lele',
    transports: ["websocket", "polling"]
  }),
}));

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')