<template>
<div id="app">
  <div id="nav" class="nav">
    <div class="flex">
      <div class="breadcurmb">
        <router-link to="/renliufenxi">客流分析</router-link>
        <router-link to="/shangpuguanli">营销分析</router-link>
        <router-link to="/">监控</router-link>
      </div>
      <div class="refresh" @click="refresh">
        <img src="../assets/refresh-icon.jpg" />
      </div>
    </div>
    <div class="title">{{currentRouteName}}</div>
  </div>
  <div class="wrap" :class="backgroundClass">
    <router-view :key="$route.fullPath" />
  </div>
</div>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {},
  mounted() {
    this.sockets.subscribe('redirect_to', (data) => {
      if(this.currentRouteName !== '控制终端'){
        this.$router.push({name: data.name}).catch(()=>{});
      }
    });
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    backgroundClass(){
      if( this.$route.name=="平台预警" || this.$route.name=="商圈展示" ){
        return "hasBackground"
      } else if (this.$route.name=="5D可视化大数据仓") {
        return "overview"
      }
      else {
        return ""
      }
    }
  },
  methods: {
    refresh() {
      this.$router.push({
        path: this.$route.fullPath,
      });
    },
  },
};
</script>

<style scoped>
#app {
  font-family: SourceHanSansCN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nav {
  height: 80px;
  background: #141930;
  text-align: center;
  position: relative;
  z-index: 1;
}

.wrap {
  background: url("../assets/bg-imgage.jpg") repeat-x;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  font-family:"Microsoft YaHei",微软雅黑,"Microsoft JhengHei",华文细黑,STHeiti,MingLiu;
}
.hasBackground{background: url("../assets/bg-imgage.jpg") repeat-x}
.overview{
  /* background-color: #061d4e; */
  /* color: #1675cf; */
  color: #fff;
  padding: 0;
}
.nav .breadcurmb {
  margin-right: 10px;
}

.nav .breadcurmb a {
  color: #2186ac;
  text-decoration: none;
  border: 2px solid #2186ac;
  border-radius: 20px;
  padding: 6px 20px;
  margin-right: 20px;
}

.nav .refresh {
  height: 20px;
  cursor: pointer;
}

.title {
  color: #a2e9fd;
  font-size: 32px;
  line-height: 100px;
  box-shadow: 0 0 20px 10px #11233c inset;
  font-weight: bold;
}

.flex {
  position: absolute;
  left: 40px;
}
</style>
<style>
* {
  box-sizing: border-box;
}

body,
p {
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
  height: 100%;
}

table {
  border-collapse: collapse;
}

ul {
  list-style: none;
  padding: 0;
}

.responsive-img {
  max-width: 100%;
}
.anchorBL{display: none;}
</style>
